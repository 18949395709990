
import gql from "graphql-tag";

export const addPlateNumberMutation = gql`
  mutation addPlateNumberMutation($objects: [vehicles_insert_input!]!) {
    insert_vehicles(objects: $objects) {
      affected_rows
    }
  }
  `;

export const addDocumentMutation = gql`
mutation AddDocumentMutation($objects: [documents_insert_input!]!) {
  insert_documents(objects: $objects) {
    affected_rows
  }
}
`;


export const addBankMutation = gql`
mutation AddBankMutation($object: banks_insert_input!) {
  insert_banks_one(object: $object) {
    id
  }
}
`;

export const getParks = gql`
query getParks ($owner : Int){
  parks( where: { owner: {_eq: $owner}}) {
    name,
    address,
    featuredImage,
    isVerified,
    id
  }
}
`;

// query GetAllParks($address : String) {
//   parks(where: {address: {_eq: $address}}) {

export const GetAvailableParks = gql`
  query GetAvailableParks {
    parks {
      address
      capacity
      city
      createdAt
      featuredImage
      id
      isVerified
      lat
      long
      name
      owner
      updatedAt
      user {
        telephone
      }
    }
  }
  
  `

//(where: { _or: [{ someField: "1" }, { someField2: "2" }] })
export const GetAllParks = gql`
  query GetAllParks($address : String) {

      parks (
      where: {
        status: {_eq:true},
        _or: [
          {name: {_ilike: "Test"}},
          {city: {_ilike: "Lagos"}},
          {address: {_ilike: "Ilorin"}},
        ]
      }
    )
    {
      address
      capacity
      city
      createdAt
      featuredImage
      id
      isVerified
      lat
      long
      name
      owner
      updatedAt
      user {
        telephone
      }
    }


       
    
    
  }
  
  `






  export const GetSearchParks = gql`
  query GetAllParks($address : String) {
    parks(where:{ status: {_eq:true}, _or: [{address: {_ilike: $address}}, {city: {_ilike: $address}}, {name: {_ilike: $address}}] }) {
      address
      capacity
      city
      createdAt
      featuredImage
      id
      isVerified
      lat
      long
      name
      owner
      updatedAt
      user {
        telephone
      }
    }
  }
  
  `















export const getVehicle = gql`
query getVehicle($userId: Int!) {
  vehicles(where: {userId: {_eq: $userId}}) {
    plateNumber
    vehicleMake
    vehicleType
    vehicleColor
    id
    createdAt
    updatedAt
    userId
  }
}
`


export const getUserList = gql`
query getUserList{
  users {
    telephone
  }
}
`;