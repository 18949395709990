import React, { useEffect, useState } from "react";
import {
  MultiChatWindow,
  MultiChatSocket,
  useMultiChatLogic,
} from "react-chat-engine-advanced";
import Spinner from "../../components/Spinner";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router";

const Chatpage = (props) => {
  console.log("chatprop", props.username);
const history = useHistory();

  const chatProps = useMultiChatLogic(
    "ab110291-8ed8-473a-a530-9b81cc3d7ff7",
    props.username,
    props.token,
  );
    // Define a function to handle the back button press
    const handleBackButtonClick = () => {
      // Perform any necessary actions before navigating back
      // For example, you can add a confirmation dialog here
      // Then, navigate back using history.goBack()
      history.goBack();
    };
  
    // Attach the back button handler to the ionViewWillLeave lifecycle event
    useEffect(() => {
      const unsubscribe = history.listen((location, action) => {
        if (action === "POP") {
          // The user pressed the back button or swiped to go back
          handleBackButtonClick();
        }
      });
  
      // Clean up the event listener when the component unmounts
      return () => {
        unsubscribe();
      };
    }, [history]);

  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/home" onClick={handleBackButtonClick} icon={arrowBack} /> {/* Use IonBackButton */}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <MultiChatSocket {...chatProps} />
      <MultiChatWindow {...chatProps} style={{ height: "100%", fontFamily: "gilroy" }} />
    </IonContent>
  </IonPage>
  );
};

export default Chatpage;
