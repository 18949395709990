import "./Home.css";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import { IonButton, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonSearchbar, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import blackmarkersvg from "../../assets/blackmarker.svg";
import Geocode from "react-geocode";
import { GetAvailableParks } from "../../services/graphql/parks";
import { environment } from "../../services/environment";
import useGeolocation from "react-hook-geolocation";
import { useLazyQuery } from "@apollo/client";
import greenmarkersvg from "../../assets/greenmarker.svg";
import { DirectionsRenderer } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "../../components/Slider";
import { SwiperRef } from "swiper/react";
import { AxiosContext } from "../../services/axios";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Toast } from "@capacitor/toast";
import { chevronForwardCircle, closeCircleOutline } from "ionicons/icons";
import { useAuth } from "../../services/auth/useAuth";
import { getDistance } from "geolib";
import { libraries } from './libraries';
import { Preferences } from "@capacitor/preferences";
import jwt_decode from 'jwt-decode';
// import {BottomSheet} from 'react-spring-bottom-sheet';

// import Wallet from "../Wallet";



// import { compose, withProps } from "recompose"
//import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"






// const center = { lat: 6.601838, lng: 3.3514863 };




// const [coordinates, setCoordinates] = React.useState({
//   lat: geolocation.latitude || 6.601838,
//   lng: geolocation.longitude || 3.3514863,
// });
const options = {
  disabledDefaultUI: true,
  zoomControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  scaleControl: false,
  overviewMapControl: false,
  rotateControl: false,

  styles: [
    { elementType: "geometry", stylers: [{ color: "#eef5f6" }] },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#f0f0f0" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#d8e3e6" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#d4d4d4" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#a1d0e6" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e1efd3" }],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ],
};

function Home() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: environment.googleApiKey,
    libraries: libraries,
  });
  const { t } = useTranslation();
  const history = useHistory();
  const axios = useContext(AxiosContext);
  const { getUserDetails } = useAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const geolocation = useGeolocation();
  const [userLocation, setUserLocation] = useState<any>(null);
  const [selectedPark, setSelectedPark] = useState<any>(null);
  const [selectedParkDistance, setSelectedParkDistance] = useState<number | null>(null);
  const [userLocations, setUserLocations] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [datar, setDatar] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(4);
  const sliderRef = useRef<SwiperRef>(null);
  const [directions, setDirections] = useState<any>(null);
  const [reff, setRef] = useState<any>("");
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [modalError, setModalError] = useState("");
  
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true);

  const handleOpenBottomSheet = () => {
    setIsBottomSheetOpen(true);
  };

  const handleCloseBottomSheet = () => {
    setIsBottomSheetOpen(false);
  };
  useEffect(() => {
    Geocode.setApiKey("AIzaSyClI93us6CcSRQj2xf5bRl5ommZETwe0v8");
    // Geocode.setRegion("ng");

    Geocode.fromLatLng(geolocation.latitude, geolocation.longitude).then(
      (response: any) => {
        const address = response.results[0].formatted_address;
        setUserLocation({
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
          address: address,
        });
   
      },
      (error: any) => {
        console.error("Error getting user location:", error);
      }
    );
  }, [geolocation.latitude, geolocation.longitude]);

  const center = userLocation
  ? {
      lat: userLocation.latitude,
      lng: userLocation.longitude,
    }
  : {
      lat: 9.0,
      lng: -2.0,
    };



  useEffect(() => {
    const getUser = () => {
      getUserDetails().then((detail:any) => {
        if (detail.value) {
          const user = JSON.parse(detail.value);
          setCurrentUser(user);
          var refval = new Date().getTime().toString();
          setRef(refval);
                  // Update local storage with user data
            //  Preferences.set("user", JSON.stringify(user));
        }
      });
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    getAvailableParks,
    { error: parksError, loading: parksLoading, data: parksData },
  ] = useLazyQuery(GetAvailableParks);

  useEffect(() => {
    if (!parksData && !parksLoading) {
      getAvailableParks();
    }
  }, [getAvailableParks, parksData, parksLoading]);

  const memoizedParksData = useMemo(() => parksData, [parksData]);
const memoizedParksLoading = useMemo(() => parksLoading, [parksLoading]);

console.log("memo parks data", memoizedParksData);
  const handleMapLoad = (map: any) => {
    setMap(map);
  };
  const handleModalOpen = useCallback((props: any) => {
    history.push(`/park/${props.id}`);
  }, [history]);
  // const center = geolocation.latitude && geolocation.longitude
  // ? {
  //     lat: geolocation.latitude,
  //     lng: geolocation.longitude,
  //   }
  // : {
  //     lat: 9.0,
  //     lng: -2.0 ,
  //   };

  const remarkers = useMemo(() => {
    if (parksData) {
      return parksData.parks.map((item: any, index: number) => ({
        key: item.id,
        icon: blackmarkersvg,
        position: { lat: item.lat, lng: item.long },
        onClick: () => setSelectedPark(item),
      }));
    }
    return [];
  }, [parksData]);

  const zoom = geolocation.latitude && geolocation.longitude ? 13 : 4;
  const handleMarkerClick = (park: any) => {
    setSelectedPark(park);
  };

const handleCloseInfoWindow = useCallback(() => {
  setSelectedPark(null);
  setDirections(null);
}, []);

  console.log("selected park", selectedPark);

  useEffect(() => {
    if (selectedPark) {
      const directionsService = new window.google.maps.DirectionsService();
      const origin = new window.google.maps.LatLng(
        geolocation.latitude,
        geolocation.longitude
      );
      const destination = new window.google.maps.LatLng(
        selectedPark.lat,
        selectedPark.long
      );
  
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result: any, status: any) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);

            const distance = getDistance(
              { latitude: geolocation.latitude, longitude: geolocation.longitude },
              { latitude: selectedPark.lat, longitude: selectedPark.long }
            );
  
            setSelectedParkDistance(distance);
          }
        }
      );

    }
  }, [selectedPark]);





  var profid = currentUser?.country === 'Senegal' ? 'Sénégal' : currentUser?.country;

console.log("profi",profid);
  
  // const userArray = userLocation?.replace(
  //   /Nigeria|0|1|2|3|4|5|6|7|8|9|0|,/gi,
  //   ""
  // );
  

  // const searchKeywords = userArray != null ? userArray.trim().split(" ") : "";

  // var addr =
  // searchKeywords != "" && searchKeywords.length >= 3
  //   ? searchKeywords[0] || searchKeywords[1] || searchKeywords[2]
  //   : "Lagos";
  const userArray = userLocation ? userLocation.address?.split(' ') : [];

  const searchKeywords =
    userArray && userArray.length >= 3 ? userArray.slice(0, 3) : [];
  
  const addr = searchKeywords.length > 0 ? searchKeywords.join(' ') : 'Lagos';


React.useEffect(() => {
  const getParkss = async () => {
    await axios
      .get(`${environment.backendURL}/parks?address=${profid}`)
      .then((response: any) => {
        setDatar(response.data);
        console.log("my", response);
        // console.log("my", allData);
      });
  };

  const timer = setTimeout(() => {
    getParkss();
  }, 100);

  return () => clearTimeout(timer);
}, [profid]);


const nullEntry: any[] = []
const [notifications, setnotifications] = useState(nullEntry);

useEffect(()=>{
    PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              showToast('Push Notification permission denied');
            }
            else {
              showToast('Push Notification permission granted');
              register();
            }
          });
        }
        else {
          register();
        }
      });
},[])

const register = () => {
    console.log('Initializing HomePage');

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: Token) => {
            console.log("push registration success")
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
            alert('Error on registration: ' + JSON.stringify(error));
        }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
            setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
            setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
        }
    );
}

const showToast = async (msg: string) => {
    await Toast.show({
        text: msg
    })
}


const [showSlider, setShowSlider] = useState(false);

const handleFabClick = () => {
  setShowSlider(!showSlider);
};


const handleCountryUpdate = async () => {
  if (selectedCountry === "") {
    setModalError("Please select a country.");
    return;
  }

  try {
    // Replace {backendurl} with the actual backend URL
    const response = await axios.put(`${environment.backendURL}/users/country`, {
      country: selectedCountry,
    });

    // Update user's country in the state
    setCurrentUser((prevUser:any) => ({
      ...prevUser,
      country: selectedCountry,
    }));
    const updatedUser = { ...currentUser, country: selectedCountry };
    await Preferences.set({
      key: 'user',
      value: JSON.stringify(updatedUser),
    });

    setShowCountryModal(false);
  } catch (error) {
    setModalError("An error occurred while updating the country.");
  }
};

useEffect(() => {
  if (currentUser?.country === null || currentUser?.country === "undefined" || currentUser?.country.trim() === "") {
    setShowCountryModal(true);
  }
}, [currentUser?.country]);

const getToken = async () => {
  const result = await Preferences.get({ key: 'token' });
  return result.value; // This will contain your JWT
};

const checkTokenExpiration = async () => {
  const token = await getToken();

  if (token) {
    console.log("Token:", token);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const decodedToken: any = jwt_decode(token);

    console.log("Decoded Token:", decodedToken);
    console.log("Current Timestamp:", currentTimestamp);
    console.log("Decoded Token exp:", decodedToken.exp);
    if (decodedToken.exp < currentTimestamp) {
      try {
        console.log("got here");
        await Preferences.remove({ key: 'token' });
        console.log("Token removed");
      } catch (error) {
        // Handle error (e.g., token removal failed)
        console.error('Error while removing token:', error);
      }
    }
  } else {
    // Handle the case where the token is not found or invalid
    console.error('Token not found or invalid.');
  }
};

useEffect(() => {
  const intervalId = setInterval(() => {
    checkTokenExpiration();
  }, 60000); // Check every minute (adjust as needed)

  return () => {
    clearInterval(intervalId); // Cleanup the interval when the component unmounts
  };
}, []);

  return (
    <IonPage>
        {/* <IonHeader >
      <IonToolbar>
        {/* <div className="parks-search" onClick={() => history.push("/search")}>
          {t('wheretotext')}
        </div> /}
           <IonItem lines="none">
        <IonLabel style={{paddingLeft:"20px",fontWeight:"400", fontFamily:"gilroy-medium"}}>{t('wheretotext')}</IonLabel>
        <IonIcon style={{color:"black"}} src={bellicon} slot="end"></IonIcon>
      </IonItem>
      </IonToolbar>
      <IonToolbar>
    
        <IonSearchbar onClick={() => history.push("/search")} className="custom" mode="ios"></IonSearchbar>
      </IonToolbar>
    </IonHeader> */}
      <IonContent>
        
           <div className="parks-search" onClick={() => history.push("/search")}>
           <IonSearchbar onClick={() => history.push("/search")} className="custom" mode="ios"></IonSearchbar>
        </div>


        {isLoaded ? (
          <GoogleMap
            onLoad={handleMapLoad}
            center={center}
            zoom={zoom}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            // options={{
            //   zoomControl: false,
            //   streetViewControl: false,
            //   mapTypeControl: false,
            //   fullscreenControl: false,
            // }}
            options={options}
          >
            {/* Displaying markers or directions */}
   

            {/* {parksData &&
              parksData.parks?.map((item: any, index: number) => (
                <Marker
                  key={item.id}
                  icon={blackmarkersvg}
                  position={{ lat: item.lat, lng: item.long }}
                  // onClick={() => handleMarkerClick(item)}
                  onClick={() => setSelectedPark(item)}
                />
              ))} */}

{remarkers.map((marker:any) => (
  <Marker {...marker} />
))}

            {map &&
              geolocation.latitude &&
              geolocation.longitude &&
              !memoizedParksLoading && (
                <Marker
                draggable={false}
                // onDragEnd={handleMarkerDragEnd}
                animation={google.maps.Animation.BOUNCE}
                  position={{
                    lat: geolocation.latitude,
                    lng: geolocation.longitude,
                  }}
                  icon={{
                    url: greenmarkersvg,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                />
              )}

{selectedPark && (
  <InfoWindow
 
    position={{ lat: selectedPark.lat, lng: selectedPark.long }}
    onCloseClick={handleCloseInfoWindow}
  >
    <div>
            {/* <div >
                        <img
                           className="officedetailimg"
                          alt=""
                          style={{ borderRadius: "5px",height:"200px",width:"237px" }}
                          src={
                           selectedPark.featuredImage !== "image"
                              ? selectedPark.featuredImage
                              : "https://res.cloudinary.com/doouwbecx/image/upload/v1630922072/parking_uupd6i.png"
                          }
                        />
                        {/* <div>
                          <h2>{data.name}</h2>
                          <p>{data.address}</p>
                        </div> /}
                      </div> */}
              <h3>{selectedPark.name}</h3>
              <p>{selectedPark.address}</p>
            
              <button onClick={() => handleModalOpen(selectedPark)} className="book-ab">
                  {t("res.space")}
                </button>
             
              {/* {selectedParkDistance !== null && <p>Distance: {(selectedParkDistance/1000).toFixed(2)} km</p>} */}
              {selectedParkDistance !== null && (
      <p style={{fontSize:"18px", fontFamily:"gilroy-bold"}}>Distance: {(selectedParkDistance / 1000).toFixed(2)} km</p>
    )}
          
            </div>
  </InfoWindow>
   
)}
  {directions && <DirectionsRenderer directions={directions}  options={{
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: "green",
        strokeWeight: 5, 
      },
    }}/>}
          </GoogleMap>
        ) : (
          <div>Loading map...</div>
        )}
        {/* <div className="slider" >
         <Slider sliderRef={sliderRef} data={datar} address={addr} userLocation={userArray} />
        </div> */}
        
           {/* <BottomSheet
           
          open={isBottomSheetOpen}
          blocking={false}
          onDismiss={handleCloseBottomSheet}
         snapPoints={({ maxHeight }) => [maxHeight / 4, maxHeight * 1]}
        >
          {/* Content for user wallet */}
          {/* Replace this with your own content /}
          <div className="wallet-card">
     
            <Wallet/>
          </div>
          <div className="wallet-buttons">
            <button className="fund-button">Fund Wallet</button>
          </div>
        </BottomSheet>

        {/* Add the bottom sheet trigger /}
        <button className="bottom-sheet-trigger" onClick={handleOpenBottomSheet}>
          Open Bottom Sheet
        </button> */}
           <IonFab horizontal="start" vertical="center" slot="fixed">
          <IonFabButton onClick={handleFabClick}>
          <IonIcon icon={showSlider ? closeCircleOutline : chevronForwardCircle} />
          </IonFabButton>
        </IonFab>

        {/* Slider */}
        {showSlider && (
          <div className="slider">
            <Slider sliderRef={sliderRef} data={datar} address={addr} userLocation={userArray} />
          </div>
        )}
        <IonModal id="transfer-modal" isOpen={showCountryModal}>
        <IonHeader mode="md">
            <IonToolbar mode="md">
              <IonTitle>
                <IonText
                  class="auth__title"
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
              

                  <h2 > Please select your country</h2>
                  <div></div>
                </IonText>
              </IonTitle>
            </IonToolbar>
          </IonHeader>
  <IonContent className="ion-padding">
    <IonItem   style={{
            border: "2px solid #E6E6E6",
            borderRadius: "99px",
            height: "46px",
            backgroundImage:
              "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
          }}>
              <IonLabel style={{ padding: "0px 20px" }}>
           Select your country
          </IonLabel>
    <IonSelect
      value={selectedCountry}
      onIonChange={(e) => setSelectedCountry(e.detail.value)}
    >
      <IonSelectOption value="United States">United States</IonSelectOption>
      <IonSelectOption value="Nigeria">Nigeria</IonSelectOption>
      <IonSelectOption value="Senegal">Senegal</IonSelectOption>
    </IonSelect>
    </IonItem>
    <br/>
    <br/>
    <IonButton className="carbon" mode="ios" shape="round" size="large" expand="full" onClick={handleCountryUpdate}>Continue</IonButton>
    {modalError && <IonText color="danger">{modalError}</IonText>}
  </IonContent>
</IonModal>

      </IonContent>
    </IonPage>
  );
}


export default Home;

