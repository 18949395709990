import "./search.css";
import debounce from 'lodash/debounce';
import { GetAllParks, GetSearchParks } from "../../services/graphql/parks";
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {
  addOutline,
  arrowBack,
  closeOutline,
  locationOutline,
  removeOutline,
  searchOutline,
} from "ionicons/icons";

import { AxiosContext } from "../../services/axios";
import Geocode from "react-geocode";
import { GetUserWalletInfo } from "../../services/graphql/wallet";
import { environment } from "../../services/environment";
import gql from "graphql-tag";
import moment from "moment";
import useGeolocation from "react-hook-geolocation";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useAuth, useUserId } from "../../services/auth/useAuth";
import carcrash from "../../assets/img/carcrash.svg";
import navicon from "../../assets/img/naviconbg.svg";
import verificon from "../../assets/img/verified.svg";
import unverificon from "../../assets/img/unverified.svg";
function SearchParks() {
  const { t }: any = useTranslation();
  const history = useHistory();
  const [address, setAddress] = React.useState<any>("");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [stopMessage, SetStopMessage] = useState<string>("");
  const [showToast1, setShowToast1] = useState(false);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);
  const [category, setCategory] = useState("Car");
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [parkingDate, setParkingDate] = useState<string>("");
  const [parkingTime, setParkingTime] = useState<string>("");
  const [inputIncrement, setInputIncrement] = useState(1);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const currentUserId = useUserId();
  const [present] = useIonAlert();
  const { getUserDetails } = useAuth();
  const geolocation = useGeolocation();
  const [parkData, setParkData] = useState<any>([]);
  const [userLocation, setUserLocation] = useState<any>(null);
  const userArray = userLocation?.replace(
    /Nigeria|0|1|2|3|4|5|6|7|8|9|0/gi,
    ""
  );
  const [SearchField, setSearchField] = useState("");

  const handleModalOpen = (props: any) => {
    // setShowModal(true);
    // setSelectedObj(props);
    return (window.location.href = `/park/${props.id}`);
  };

  const axios = useContext(AxiosContext);

  const GetParkPrice = gql`
      query GetParkPrice {
      park_price(where: {parkId: {_eq: ${selectedObj?.id} }}) {
          id
          parkId
          price
          rate
          type
          park {
            user {
              telephone
            }
          }
      }
      }
      `;

  const getVehicle = gql`
  query getVehicle {
  vehicles(where: {userId: {_eq: ${currentUserId}}}) {
      plateNumber
      id
      userId
  }
  }
  `;

  Geocode.setApiKey("AIzaSyClI93us6CcSRQj2xf5bRl5ommZETwe0v8");

  Geocode.fromLatLng(geolocation.latitude + 0, geolocation.longitude + 0).then(
    (response: any) => {
      const address = response.results[8].formatted_address;
      console.log("geolocation ", response);
      setUserLocation(address);
    },
    (error: any) => {
      console.error(error);
    }
  );
  useEffect(() => {
    const getUser = () => {
      getUserDetails().then((detail) => {
        if (detail.value) {
          const user = JSON.parse(detail.value);
          setCurrentUser(user);
        }
      });
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  var profid = currentUser?.country === 'Senegal' ? 'Sénégal' : currentUser?.country;

  const { data } = useQuery(GetSearchParks, {
    variables: {
      address: `%${address || userArray || profid}%`,
    },
    // skip: !address,
    fetchPolicy: "cache-first",
  });

  const { data: dataR } = useQuery(GetUserWalletInfo, {
    variables: {
      id: currentUserId,
    },
    skip: !currentUserId,
  });

  const { data: price } = useQuery(GetParkPrice);

  // useEffect(() => {
  //   // Function to fetch park data from the API
  //   const fetchParkData = async () => {
  //     try {
  //       if (address.length > 3) {
  //       const response = await axios.get(
  //         `${environment.backendURL_two}/api/parks`,
  //         {
  //           params: {
  //             search: address.length < 1 ? userArray : address,
  //           },
  //         }
  //       );

  //       setParkData(response.data);
  //       console.log("response.data", response.data);
  //     }
  //     } catch (error) {
  //       console.error("Error fetching park data:", error);
  //     }
  //   };

  //   // Call the function to fetch data when the component mounts
  //   fetchParkData();
  // }, [address, userArray]);

  const planSelected = (data: any) => {
    setSelectedPlan(data);
  };

  const reservation = () => {
    if (selectedPlan === null) {
      present({
        cssClass: "my-css",
        header: "You need to select a Plan",
        buttons: ["Okay"],
        // onDidDismiss: (e) => console.log('did dismiss'),
      });
    } else {
      setShowConfirmModal(true);
    }
  };

  const { data: vehicle } = useQuery(getVehicle);

  // useEffect(() => {
  //   if (parkData.length < 1) {
  //   setAddress(userArray);
  //   }
  // }, [userArray]);

  console.log("search", address);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md">
          {/* <div className="parks-search" onClick={() => history.push("/search")}>
          {t('wheretotext')}
        </div> */}
          <IonItem color="none" lines="none">
            <IonLabel
              style={{
                paddingLeft: "20px",
                fontWeight: "400",
                fontFamily: "gilroy-medium",
              }}
            >
              {t("wheretotext")}
            </IonLabel>
            <IonIcon
              onClick={() => {
                history.goBack();
              }}
              style={{ color: "black" }}
              icon={closeOutline}
              slot="end"
            ></IonIcon>
          </IonItem>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={address}
            debounce={3000}
            onIonChange={(e) => setAddress(e.detail.value)}
            className="custom"
            mode="ios"
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          <div>
            {/* {data && data?.parks.length < 1 && (
            
             

              <div
                style={{ alignSelf: "center", marginTop: "50%" }}
                className="pic-item"
              >
                <div>
                  <IonImg className="crashimg" alt="" src={carcrash} />
                </div>
                <h6 className="no-park">
                  {t("no.parks")}&nbsp;{address || userArray}
                </h6>
              </div>
            )} */}

            {parkData && parkData[0] != null
              ? parkData.map((data: any) => {
                  return (
                    <div>
                      <IonCard
                        key={data.id}
                        onClick={() => handleModalOpen(data)}
                        className="ion-padding"
                      >
                        <IonGrid>
                          <IonRow>
                            <IonCol size="3">
                              <img
                                alt=""
                                src={
                                  data?.featuredImage !== "image"
                                    ? data?.featuredImage
                                    : "https://res.cloudinary.com/doouwbecx/image/upload/v1630922072/parking_uupd6i.png"
                                }
                                style={{
                                  borderRadius: "5px",
                                  height: "110px",
                                  width: "76px",
                                  objectFit: "cover"
                                }}
                              />
                            </IonCol>
                            <IonCol size="9">
                              <IonRow style={{ width: "100%" }}>
                                <IonLabel style={{ width: "100%" }}>
                                  <IonText
                                    style={{
                                      fontSize: "10px",
                                      fontFamily: "gilroy-medium",
                                      color: "#808080",
                                      fontWeight: "500",
                                    }}
                                    slot="start"
                                  >
                                    Close to your location
                                  </IonText>
                                  {/* <IonBadge className="ion-padding" slot="end">500</IonBadge> */}
                                </IonLabel>
                              </IonRow>
                              <IonRow style={{ width: "100%" }}>
                                <IonItem style={{ width: "100%" }} lines="none">
                                  <IonLabel style={{ width: "100%" }}>
                                    <IonText
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "gilroy-bold",
                                        color: "#000000",
                                        fontWeight: "500",
                                      }}
                                      slot="start"
                                    >
                                      {data.name}
                                    </IonText>
                                  </IonLabel>
                                  <IonBadge slot="end">
                                    {data.distance.toFixed(2)}km
                                  </IonBadge>
                                </IonItem>
                              </IonRow>
                              <IonRow style={{ width: "100%" }}>
                                <IonLabel>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <IonIcon
                                      className="detail-icon"
                                      src={navicon}
                                    />
                                    <span style={{ paddingTop: "10px" }}>
                                      {data.address}
                                    </span>
                                  </div>
                                </IonLabel>
                              </IonRow>
                              <IonRow style={{ width: "100%" }}>
                                <IonItem lines="none">
                                  <IonBadge
                                    style={{ marginRight: "4px" }}
                                    className="detail-badge"
                                    slot="start"
                                  >
                                    {data.capacity}+
                                  </IonBadge>
                                  <IonText
                                    style={{
                                      color: "#808080",
                                      fontFamily: "gilroy-bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    parking spots left
                                  </IonText>
                                </IonItem>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  );
                })
              : ""}

            {data &&
              data?.parks.map((data: any) => {
                return (
                  <div
                    className="search-item"
                    key={data.id}
                    onClick={() => handleModalOpen(data)}
                  >
                    <div className="status-check">
                      <div className="item-div1">
                        <img
                          alt=""
                          src={
                            data.featuredImage !== "image"
                              ? data.featuredImage
                              : "https://res.cloudinary.com/doouwbecx/image/upload/v1630922072/parking_uupd6i.png"
                          }
                          style={{ borderRadius: "5px",  objectFit: "cover" }}
                        />
                        <div>
                          <h2>{data.name}</h2>
                          <br />
                          <p>{data.address}</p>
                        </div>
                      </div>
                      <button
                        className={data.isVerified ? "verified" : "nt-verified"}
                      >
                        {data.isVerified ? "Verified" : "Unverified"}
                      </button>
                    </div>

                    <div className="item-div2">
                      <div>
                        <p>Available Spaces</p>
                        <h6>{data.capacity}</h6>
                      </div>
                      <div>
                        <p>{t("rating.user")}</p>
                        <h6>4.7/5</h6>
                      </div>
                      {/* <button>Reserve</button> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </IonContent>

      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Reservation booked."
        duration={3000}
      />
    </IonPage>
  );
}

export default SearchParks;
