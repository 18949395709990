import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { IonButton, IonContent, IonText, IonIcon, IonItem, IonLabel, IonInput, useIonToast } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useAuth } from "../../../services/auth/useAuth";

import * as yup from "yup";

import "../styles/otp.scss";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type ChangePasswordFormData = {
  password: string;
  confirmPassword: string;
};

const ResetOtp = () => {
  const { verifyResetPassword, getUserDetails } = useAuth();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = yup.object().shape({
    password: yup.string().required("Please enter your new Password"),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const { control, handleSubmit, formState } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [present, dismiss] = useIonToast();

  const [pageOTP, setPageOTP] = useState<string[]>([]);
  const [pinId, setPinId] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const getPin = () => {
      getUserDetails().then((detail) => {
        if (detail.value) {
          const user = JSON.parse(detail.value);
          console.log("user",user);
          setPinId(user.pinId);
          setUserId(user.userId);
        }
      });
    };

    getPin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetPassword = (data: ChangePasswordFormData) => {
    setIsSubmitting(true);

    console.log(pageOTP.join(""), userId);

    verifyResetPassword(pageOTP.join(""), userId, data.password)
      .then((response) => {
        console.log(response);
        if (response.data === true) {
          present({
            buttons: [{ text: "Ok", handler: () => dismiss() }],
            message: "Your password has been changed. Login with your new password",
            onDidDismiss: () => history.push("/"),
          });
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        setErrorMessage("Please enter the correct OTP. ");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onOtpInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const id = parseInt(event.currentTarget.id, 10);
    if (event.currentTarget.id === "Backspace") {
      const prevId = id - 1;
      document.getElementById(`${prevId === 0 ? 1 : prevId}`)!.focus();
      return;
    }
    if (event.currentTarget.value.length > 0) {
      if (id === 4) {
        document.getElementById(`${id}`)!.blur();
        return;
      }
      const nextId = id + 1;
      document.getElementById(`${nextId}`)!.focus();
    }
  };

  const onOtpInputChange = (id: string, value: string) => {
    if (value && pageOTP[parseInt(id, 10) - 1]) {
      return;
    }

    const updatedOTP = [...pageOTP];
    updatedOTP[parseInt(id, 10) - 1] = value;
    setPageOTP(updatedOTP);
  };

  const onOTPInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value, id } = event.currentTarget;
    if (value && pageOTP[parseInt(id, 10) - 1] && event.key !== "Backspace") {
      return false;
    }
  };

  return (
    <IonContent
      class="auth"
      style={{
        "--padding-top": "120px",
        "--padding-start": "24px",
        "--padding-end": "30px",
        "--padding-bottom": "30px",
      }}
    >
      <IonText class="auth__title">
        <p onClick={() => history.goBack()}>
          <IonIcon icon={arrowBack} size="large" />
        </p>
        <h3>Enter your New Password</h3>
      </IonText>
      <IonText color="medium" class="auth__sub-title">
        <p>Enter the 4-digit code sent to you</p>
      </IonText>

      <form className="auth__form" onSubmit={handleSubmit(handleResetPassword)}>
        <div className="otp">
          <input
            type="number"
            id="1"
            name="firstDigit"
            maxLength={1}
            onKeyDown={onOTPInputKeyDown}
            onKeyUp={onOtpInputKeyUp}
            onChange={(event) => {
              onOtpInputChange("1", event.currentTarget.value);
            }}
          />
          <input
            type="number"
            id="2"
            name="firstDigit"
            maxLength={1}
            onKeyDown={onOTPInputKeyDown}
            onKeyUp={onOtpInputKeyUp}
            onChange={(event) => {
              onOtpInputChange("2", event.currentTarget.value);
            }}
          />
          <input
            type="number"
            id="3"
            name="firstDigit"
            maxLength={1}
            onKeyDown={onOTPInputKeyDown}
            onKeyUp={onOtpInputKeyUp}
            onChange={(event) => {
              onOtpInputChange("3", event.currentTarget.value);
            }}
          />
          <input
            type="number"
            id="4"
            name="firstDigit"
            maxLength={1}
            onKeyDown={onOTPInputKeyDown}
            onKeyUp={onOtpInputKeyUp}
            onChange={(event) => {
              onOtpInputChange("4", event.currentTarget.value);
            }}
          />
        </div>

        <IonItem>
          <IonLabel position="floating">New Password</IonLabel>

          <Controller
            control={control}
            name="password"
            render={({ field: { onChange } }) => <IonInput type="password" onIonChange={onChange}></IonInput>}
          />
        </IonItem>

        {formState.errors.password && <IonText color="danger">{formState.errors.password.message}</IonText>}

        <IonItem>
          <IonLabel position="floating">Confirm Password</IonLabel>

          <Controller
            control={control}
            name="confirmPassword"
            render={({ field: { onChange } }) => <IonInput type="password" onIonChange={onChange}></IonInput>}
          />
        </IonItem>

        {formState.errors.confirmPassword && (
          <IonText color="danger">{formState.errors.confirmPassword.message}</IonText>
        )}

        <IonButton mode="ios" color="primary" expand="full" type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Resetting..." : "Reset Password"}
        </IonButton>

        <IonText color="danger">{errorMessage}</IonText>

        <IonText class="auth__form__link" color="success">
          <a href="/">Back to Sign-In</a>
        </IonText>
      </form>
    </IonContent>
  );
};

export default ResetOtp;
