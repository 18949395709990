import React from 'react';
import { IonSpinner, IonContent, IonPage } from '@ionic/react';
import  logoIcon  from '../../assets/img/logo-icon.png';
import './spinner.css';

const Spinner = () => (
  <IonPage>
    <IonContent>
      <div className="spinner-container">
        <img src={logoIcon} alt="Logo" className="logo-icon" />
        <IonSpinner className="spinner" name="crescent" />
      </div>
    </IonContent>
  </IonPage>
);

export default Spinner;
