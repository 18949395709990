import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "@ionic/react/css/ionic-swiper.css";
import "./slidder.css";
import SwiperCore from "swiper";
import { Autoplay, Pagination, Scrollbar, Zoom } from "swiper";
import SwiperInstance from "swiper";
import {

 
  IonToast,
  IonicSlides,
  useIonAlert,
} from "@ionic/react";

import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState, useRef, useImperativeHandle, forwardRef, RefObject } from "react";
import { useUserId } from "../../services/auth/useAuth";
import React from 'react';
import { useTranslation } from 'react-i18next';

type SliderProps = {
  data: any;
  address: any;
  userLocation: any;
  sliderRef: RefObject<SwiperCore | any>;
};

export type SliderRef = {
  slideTo: (index: number, speed?: number, runCallbacks?: boolean) => void;
};

function Slider({ data, address, userLocation, sliderRef }: SliderProps) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [present] = useIonAlert();
  const {t} = useTranslation();
  const currentUserId = useUserId();
  const [showToast1, setShowToast1] = useState(false);
  const history = useHistory();
  const [category, setCategory] = useState("Car");
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [parkingDate, setParkingDate] = useState<string>("");
  const [parkingTime, setParkingTime] = useState<string>("");
  const [inputIncrement, setInputIncrement] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);

  const slideOpts = {
    initialSlide: 1,
    speed: 200,
    slidesPerView: 1,
  };

  const handleModalOpen = (props: any) => {
    return (window.location.href = `/park/${props.id}`);
  };
 console.log("data", data);
  const GetParkPrice = gql`
        query GetParkPrice {
        park_price(where: {parkId: {_eq: ${selectedObj?.id} }}) {
            id
            parkId
            price
            rate
            type
            park {
              user {
                telephone
              }
            }
        }
        }
        `;
console.log("slide", data);
  const getVehicle = gql`
    query getVehicle {
    vehicles(where: {userId: {_eq: ${currentUserId}}}) {
        plateNumber
        id
        userId
    }
    }
    `;

  const { data: vehicle } = useQuery(getVehicle);

  const { data: price } = useQuery(GetParkPrice);

  const swiperRef = React.useRef<SwiperInstance>(null);


  // useImperativeHandle(ref, () => ({
  //   slideTo(index: number, speed?: number, runCallbacks?: boolean) {
  //     swiperRef.current?.slideTo(index, speed, runCallbacks);
  //   },
  // }));

  const navigateToSlide = (parkId: string) => {
    if (swiperRef.current) {
      const parkIndex = data?.parks.findIndex((park: any) => park.id === parkId);
      if (parkIndex !== -1) {
        swiperRef.current.slideTo(parkIndex, 300); // 300 is the speed (duration) of transition in ms
      }
    }
  };
  return (
    
    <div className="wrapper">
      {data && data?.parks.length < 1 && (
        <Swiper >
          <SwiperSlide key={data.id} onClick={() => handleModalOpen(data)}>
            <div className="slide-item2">
              <img
                src="https://res.cloudinary.com/doouwbecx/image/upload/v1630960077/abd-removebg-preview_tjhb8v.png"
                alt=""
              />
              <h6>No Park(s) Available at {address || userLocation}</h6>
            </div>
          </SwiperSlide>
        </Swiper>
      )}
      {data?.parks.length >= 1 && (
        <Swiper
        ref={sliderRef}
        modules={[
          Autoplay,
          Pagination,
          Scrollbar,
          Zoom,
          IonicSlides,
        ]}
        // autoplay={{
          
        //   delay: 4000,
        //   disableOnInteraction: false,
        // }}

        keyboard={true}
        pagination={false}
        scrollbar={false}
        zoom={false}
      >
          {data &&
            data?.parks.map((data: any) => {
              return (
                <SwiperSlide key={data.id} onClick={() => handleModalOpen(data)}>
                  <div className="slide-item">
                    <div className="status-check">
                      <div className="item-div1">
                        <img
                          alt=""
                          style={{ borderRadius: "5px", width:"80px",height:"80px" }}
                          src={
                            data.featuredImage !== "image"
                              ? data.featuredImage
                              : "https://res.cloudinary.com/doouwbecx/image/upload/v1630922072/parking_uupd6i.png"
                          }
                        />
                        <div>
                          <h2>{data.name}</h2>
                          <p>{data.address}</p>
                        </div>
                      </div>
                      <button
                        className={data.isVerified ? "verified" : "nt-verified"}
                      >
                        {data.isVerified ? "Verified" : "Unverified"}
                      </button>
                    </div>

                    <div className="item-div2">
                      <div>
                        <p>{t("slot.left")}</p>
                        <h6>{data.capacity}</h6>
                      </div>
                      <div>
                        <p>{t("rating.user")}</p>
                        <h6>4.7/5</h6>
                      </div>
                      {/* <button>Reserve</button> */}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}

      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Reservation booked."
        duration={3000}
      />
    </div>
 );
};

export default Slider;

// const planSelected = (data: any) => {
//   setSelectedPlan(data);
// };

// const reservation = () => {
//   if (selectedPlan === null) {
//     present({
//       cssClass: "my-css",
//       header: "You need to select a Plan",
//       buttons: ["Okay"],
//       // onDidDismiss: (e) => console.log('did dismiss'),
//     });
//   } else {
//     setShowConfirmModal(true);
//   }
// };

// const increment = () => {
//   if (inputIncrement >= 12) {
//     return;
//   } else {
//     setInputIncrement(inputIncrement + 1);
//   }
// };

// const decrement = () => {
//   if (inputIncrement <= 1) {
//     return;
//   } else {
//     setInputIncrement(inputIncrement - 1);
//   }
// };

// const completeReserve = () => {
//   present({
//     cssClass: "my-css",
//     header: "Confirm Reservation",
//     buttons: [
//       "Cancel",
//       {
//         text: "Ok",
//         handler: async () => {
//           var hourly = moment(parkingDate)
//             .add(inputIncrement, "hours")
//             .format();
//           var daily = moment(parkingDate)
//             .add(inputIncrement, "days")
//             .format();
//           var monthly = moment(parkingDate).add(inputIncrement, "M").format();

//           const parkOwnerMobileNumber = selectedPlan?.park?.user?.telephone;
//           try {
//             await axios.post(
//               `${environment.backendURL}/booking/createReservation/`,
//               {
//                 parkId: selectedObj?.id,
//                 vehicleId: selectedVehicle,

//                 amount: selectedPlan?.price * inputIncrement,
//                 userId: currentUserId,
//                 startTime: parkingDate,
//                 endTime:
//                   selectedPlan?.rate === "Hourly"
//                     ? hourly
//                     : selectedPlan?.rate === "Monthly"
//                     ? monthly
//                     : daily,
//                 parkOwnerNumber: `${parkOwnerMobileNumber}`,
//                 bookedRate: selectedPlan?.rate,
//                 bookedDuration: inputIncrement,
//               }
//             );
//             await present({
//               cssClass: "my-css",
//               header: "Reservation Successful",
//               buttons: [
//                 {
//                   text: "Ok",
//                   handler: () => {
//                     return history.push("/success/reserved");
//                   },
//                 },
//               ],
//             });
//           } catch (error) {
//             console.log(error);
//           }
//         },
//       },
//     ],
//     // onDidDismiss: (e) => console.log('did dismiss'),
//   });
// };
