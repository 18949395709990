import React, { useState } from "react";
import { IonText, IonContent, IonButton, IonItem, IonLabel, IonInput, IonIcon } from "@ionic/react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from 'react-phone-number-input';
import { useAuth, LoginFormData } from "../../../services/auth/useAuth";
import { useHistory } from "react-router-dom";
import { arrowBack, returnUpBackOutline } from "ionicons/icons";
import parsePhoneNumberFromString from "libphonenumber-js/min";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const history = useHistory();
  const { t } = useTranslation();
  const { resetPwd, storeUserDetails } = useAuth();

  const validationSchema = yup.object().shape({
    telephone: yup.number().required(),
  });

  const { control, handleSubmit, formState } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const countries:any = [
    { label: 'United States', value: '+1', code:'US' },
    { label: 'Nigeria', value: '+234',code:'NG' },
    { label: 'United Kingdom', value: '+44', code:'GB' },
    { label: 'India', value: '+91', code:'IN' },
    { label: 'Senegal', value: '+221', code:'SN' },

  ];
 

  const resetUser = (data: LoginFormData) => {
    setIsSubmitting(true);

    resetPwd(data).then((response) => {
      console.log("response", response);
      // if (!response.data.pinId) {
      //   //If No PinId was sent back from the response, it means the user doesn't exist on the DB
      //   setErrorMessage(
      //     "Uh-oh! There is no account associated with this Phone Number. Please verify if it's correct and try again!"
      //   );
      //   setIsSubmitting(false);
      //   return;
      // }
      storeUserDetails(response.data);
      history.push("/auth/resetOtp");
      setIsSubmitting(false);
    });
  };

  return (
    <IonContent
      class="auth"
      style={{
        "--padding-top": "120px",
        "--padding-start": "24px",
        "--padding-end": "30px",
        "--padding-bottom": "30px",
      }}
    >
      <IonText class="auth__title">
        <p onClick={() => history.goBack()}>
          <IonIcon icon={arrowBack} size="large" />
        </p>
        <h3>Reset Password</h3>
      </IonText>

      <form className="auth__form" onSubmit={handleSubmit(resetUser)}>
        {/* <IonItem>
          <IonLabel position="floating">Phone Number</IonLabel>

          <Controller
            control={control}
            name="telephone"
            render={({ field: { onChange } }) => <IonInput type="tel" onIonChange={onChange}></IonInput>}
          />
        </IonItem> */}

        <>
  <IonItem lines="none">
        <IonLabel>{t('loginphonenum')}</IonLabel>
      
         <Controller
                      control={control}
                      name="telephone"
                      render={({ field: { onChange, value } }) => (
  <PhoneInput
        className="phone-input-container"
          placeholder={t('loginphonenumph')}
          international
          countryCallingCodeEditable={false}
          defaultCountry="NG"
          value={value}
          onChange={onChange}
          setValueAs={(value: string) => (value ? parsePhoneNumberFromString(value)?.number : '')}
      
          countries={countries.map((country:any) => country.code)}
       
          disableDropdown
         
        />
        )}
       />
        </IonItem>
       
</>

        {formState.errors.telephone && (
          <IonText color="danger">
            <span>Please enter Phone Number</span>
          </IonText>
        )}

        <IonButton mode="ios" shape="round" color="primary" expand="full" disabled={isSubmitting} type="submit">
          {isSubmitting ? "Submitting..." : "Reset Password"}
        </IonButton>

        <IonText color="danger">{errorMessage}</IonText>
      </form>
    </IonContent>
  );
}

export default ResetPassword;
