import { Suspense, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../services/auth/useAuth";
import Spinner from "../Spinner";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isLoggedIn } = useAuth();

  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    const checkLogin = async () => {
      const check = await isLoggedIn();
      setLoggedIn(check);
    };
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /Login page
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? <Suspense fallback={<div><Spinner/></div>}><Component {...props} /></Suspense> : <Redirect to="/auth/login" />
      }
    />
  );
};

export default PrivateRoute;
