import { IonContent, IonPage } from '@ionic/react'
import "./success.css"
import {useHistory} from "react-router-dom"

function Success(props: any) {
    var param = props.match.params.string
    const history = useHistory()

    const route = async(id: any) => {
        await history.push(`/${id}`)
    }
    console.log({param})

    return (
        <IonPage>
            <IonContent>
                <div className="success-container">
                    <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1632243770/ss_yjlixy.png" alt="" />
                    <p>Success</p>
                    <button onClick={() => route(param)} style={{background: 'white', padding: '10px', color: '#1c8e45', fontWeight: 500}} > Continue to {param} </button>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Success
