import React from 'react';
import { IonContent, IonButton } from '@ionic/react';
import './Onboarding.css'; // Create a CSS file for styling
import parkwellLogo from '../../assets/img/parkwell-logo.png'; // Import the image asset
import { Link, useHistory } from 'react-router-dom';

const OnboardingPage = () => {
  const history = useHistory();

  const handleClick = () => {
    // Perform any necessary logic before navigating

    // Navigate to the desired route with a router animation
    history.push('/auth/register', { animation: 'ios-transition', direction: 'forward'  });
  };

  return (
    <IonContent>
      <div className="background-image">
        <div className="logo-container">
          <img
            src={parkwellLogo} // Use the imported image asset here
            alt="Parkwell Logo"
            className="logo-image"
          />
        </div>
        <div className="text-container">
          <span className="text">
            Get perfect parking spaces around you.
          </span>
        </div>
        <div className="button-container">
    
          <button  onClick={handleClick} className="get-started-button">
            Get Started
          </button>
       
          <button onClick={() => history.push("/auth/login")}  className="login-button">
            Login
          </button>
         
        </div>
      </div>
    </IonContent>
  );
};

export default OnboardingPage;
