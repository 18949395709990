import { IonContent, IonHeader, IonIcon, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react'
import { useState } from 'react'
import "./mapRoute.css"
import { withGoogleMap, GoogleMap, DirectionsRenderer } from "react-google-maps";
import useGeolocation from 'react-hook-geolocation'
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import { useUserId } from "../../services/auth/useAuth";
import { arrowBack } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';


const mapContainerStyle = {
  width: "100%",
  // height: "400px",
};

const options = {
  disabledDefaultUI: true,
  zoomControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  scaleControl: false,
  overviewMapControl: false,
  rotateControl: false,

};

function MapRoute(props: any) {
  const geolocation = useGeolocation()
  var paramId = props.match.params.id
  const currentUserId = useUserId();
  var id = parseInt(paramId)
  const history = useHistory()

  /*const [coordinates, setCoordinates] = React.useState({
    lat: geolocation.latitude || 6.601838,
    lng: geolocation.longitude || 3.3514863,
  });

  const center = coordinates; */



  const getReservation = gql`
      query getReservation {
        booking(where: {id: {_eq: ${id}}}) {
          amount
          id
          endTime
          startTime
          status
          parkId
          createdAt
          park {
            address
            capacity
            city
            featuredImage
            name
            isVerified
            owner
            lat
            long
          }
          vehicle {
            plateNumber
            id
          }
        }
      }
      `

  const { error: reservationError, loading: reservationLoading, data: reservationData } = useQuery(getReservation)

  //console.log({ reservationData, geolocation })


  const directionsService = new google.maps.DirectionsService();

  const origin = {
    lat: geolocation?.latitude,
    lng: geolocation?.longitude,
  };
  const destination = { lat: reservationData?.booking[0].park.lat, lng: reservationData?.booking[0].park.long };
  const [direction, setDirection] = useState<any>()

  directionsService.route(
    {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirection(result)
       // console.log(result);
      } else {
        console.error(`error fetching directions ${result}`);
      }
    }
  );

  //console.log({ direction })

  /*useEffect(() => {
    const loadData = () => {
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirection(result)
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
    loadData()
  }, [geolocation]) */


  const ParkMap = withGoogleMap((props: any) => (
    <GoogleMap
      zoom={16}
      center={origin}
      options={options}>

      <DirectionsRenderer
        directions={direction}
      />
    </GoogleMap>
  ));



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonText class="auth__title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <IonIcon
                onClick={() => {
                  history.goBack();
                }}
                icon={arrowBack}
                size="small"
              ></IonIcon>
              <h2 style={{ marginTop: '3%' }}> {reservationData?.booking[0]?.park?.name}</h2>
              <div></div>
            </IonText>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ParkMap
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          isMarkerShown
        />
      </IonContent>
    </IonPage>
  )
}

export default MapRoute
