import "../styles/auth.scss";
import "react-phone-number-input/style.css";
import "./countryflags.css";
import * as yup from "yup";

import { Controller, useForm, useWatch } from "react-hook-form";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { Link, useHistory } from "react-router-dom";
import { RegisterFormData, useAuth } from "../../../services/auth/useAuth";
import { useContext, useEffect, useRef, useState } from "react";

import { AxiosContext } from "../../../services/axios";
import PhoneInput from "react-phone-number-input";
import { environment } from "../../../services/environment";
import { isValidPhoneNumber } from "react-phone-number-input";
import parsePhoneNumberFromString from "libphonenumber-js/min";
//import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { alertCircleOutline, warningOutline } from "ionicons/icons";
import parkwellLogo from "../../../assets/img/svglogo.svg";
const Register = () => {
  const axios = useContext(AxiosContext);
  const [isInputActive, setIsInputActive] = useState(false);
  const [steps, setSteps] = useState<any>(0);
  const handleInputFocus = () => {
    setIsInputActive(true);
  };

  const handleInputBlur = () => {
    setIsInputActive(false);
  };
  const countries: any = [
    { label: "United States", value: "+1", code: "US" },
    { label: "Nigeria", value: "+234", code: "NG" },
    { label: "United Kingdom", value: "+44", code: "UK" },
    { label: "India", value: "+91", code: "IN" },
    { label: "Senegal", value: "+221", code: "SN" },
  ];
  const [selectedcountry, setSelectedCountry] = useState<any>("");
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().required().email(),
    telephone: yup.number().required(),
    password: yup.string().required(),
    confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  referral: yup.string().notRequired(),
   
  });

  const { control, handleSubmit,  formState: { errors }, } = useForm<RegisterFormData>({
    resolver: yupResolver(validationSchema),
  });

  const history = useHistory();

  const { register, storeToken, storeUserDetails } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const [countryCode, setCountryCode] = useState<any>(countries[0].value);

  const { getAuthToken } = useAuth();
  const [authToken, setAuthToken] = useState<string>();
  getAuthToken().then((token) => {
    if (token.value != null) {
      setAuthToken(token.value);
    }
  });
  axios.defaults.headers.common = { Authorization: `bearer ${authToken}` };

  const registerUser = (data: RegisterFormData) => {
    setIsSubmitting(true);
    let mailval = data.email.replace("@", "%40");

    register(data)
      .then(async (response) => {
        if (
          (response.status === 200 ||
            response.status === 204 ||
            response.status === 201) &&
          response?.data?.error == null
        ) {
          console.log(response);
          await storeToken(response.data.access_token);
          await storeUserDetails({
            ...response.data.data,
            ...JSON.parse(response.config.data), //saving this in the user details, cos we don't have the first name & last name in the above response from the register api
          });

          try {
            fetch(
              `https://parkwell.us5.list-manage.com/subscribe/post?u=fb7776af0310e4f643a48e6a7&id=a8b5684025&EMAIL=${mailval}&FNAME=${data.firstName}&LNAME=${data.lastName}&CATEGORY=PARKOWNER`,
              {
                method: "GET",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": "true",
                  authority: "parkwell.us5.list-manage.com",
                  mode: "no-cors",
                },
                mode: "no-cors",
              }
            )
              .then((response) => console.log(response))
              .then((data) => console.log(data));
          } catch {}

          //go to the Verify OTP Page...
          history.push("/verifyOtp");
        } else {
          var msg = response?.data?.error?.message;
          if (msg.includes("duplicate key value")) {
            setErrorMessage(
              "An account with the number or email already exists."
            );
          } else {
            setErrorMessage(response?.data?.error?.message);
          }
        }

        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log("Errors", error);
        setErrorMessage(error?.message);
      });
  };

  return (
    <IonPage>
      <IonContent
        class="auth"
        style={{
          "--padding-top": "120px",
          "--padding-start": "24px",
          "--padding-end": "30px",
          "--padding-bottom": "24px",
        }}
      >
        <div className="logo-container">
          <img
            src={parkwellLogo} // Use the imported image asset here
            alt="Parkwell Logo"
            className="logo-image"
          />
        </div>

        <div className="login-card">

        <IonText class="auth__title">
          <h3>{t("signupcreateaccounttxt")}</h3>
        </IonText>
        <IonText class="auth__subtitle">
          Please provide your real credentials
        </IonText>
        <br />
        <br />

        <form className="auth__form" onSubmit={handleSubmit(registerUser)}>
          {steps < 1 ? (
            <>
            
            <div className="fl-div">
            <IonItem style={{width:"100%"}}   lines="none">
                      {/* <IonLabel style={{padding: "0px 10px 20px  " }} position="stacked">{t('signupfirstname')}</IonLabel> */}
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field: { onChange } }) => (
                          <IonInput
                            style={{
                              border: "2px solid #E6E6E6",
                              borderRadius: "99px",
                              height: "56px",
                              width:"100%",

                              backgroundImage:
                                "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
                              textIndent: "20px",
                            }}
                            placeholder="First name"
                            type="text"
                            onIonChange={onChange}
                          ></IonInput>
                        )}
                      />
                    </IonItem>

             
                  
                    <IonItem style={{width:"100%"}}     lines="none">
                      {/* <IonLabel style={{padding: "0px 10px 20px  ",  }} position="stacked">{t('signuplastname')}</IonLabel> */}
                      <Controller
                        control={control}
                        name="lastName"
                        render={({ field: { onChange } }) => (
                          <IonInput
                            style={{
                              border: "2px solid #E6E6E6",
                              borderRadius: "99px",
                              height: "56px",

                              backgroundImage:
                                "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
                              textIndent: "20px",
                            }}
                            placeholder="Last name"
                            type="text"
                            onIonChange={onChange}
                          ></IonInput>
                        )}
                      />
                    </IonItem>

             
                
            
            </div>
            {errors.firstName && (
                      <IonText color="danger">
                        <small style={{fontSize:"14px", color:"orange"}}>{t("signupfirstnameerror")}</small>
                      </IonText>
                    )}
              
              {errors.lastName && (
                      <IonText color="danger">
                        <small style={{fontSize:"14px", color:"orange"}}>{t("signuplastnameerror")}</small>
                      </IonText>
                    )}
              <>
                <IonItem lines="none">
                  {/* <IonLabel style={{padding: "0px 10px 20px  ",  }} position="stacked">{t('signupemail')}</IonLabel> */}
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange } }) => (
                      <IonInput
                        style={{
                          border: "2px solid #E6E6E6",
                          borderRadius: "99px",
                          height: "56px",
                          backgroundImage:
                            "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
                          textIndent: "20px",
                        }}
                        placeholder="Email address"
                        type="email"
                        onIonChange={onChange}
                      ></IonInput>
                    )}
                  />
                </IonItem>

                {errors.email && (
                  <IonText color="danger">
                    <span style={{fontSize:"14px", color:"orange"}}>{t("signupemailerror")}</span>
                  </IonText>
                )}

                <>
                  <IonItem
                    style={{
                      border: "2px solid #E6E6E6",
                      borderRadius: "99px",
                      height: "46px",
                      backgroundImage:
                        "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
                    }}
                  >
                    <IonLabel style={{ padding: "0px 20px" }}>
                      {t("country.select")}
                    </IonLabel>

                    <Controller
                      control={control}
                      name="country"
                      render={({ field: { onChange, value } }) => (
                        <IonSelect
                          color="green"
                          mode="ios"
                          interface="popover"
                          value={value}
                          onIonChange={onChange}
                        >
                          {countries.map((country: any) => (
                            <IonSelectOption
                              key={country.code}
                              value={country.label}
                            >
                              <div
                                className={`flag-icon flag-icon-${country.code}`}
                              ></div>
                              {`${country.label} (${country.code})`}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                    />
                  </IonItem>
                </>
              </>
            </>
          ) : (
            <>
              <>
                <IonItem lines="none">
                  <IonLabel>{t("signupphonenum")}</IonLabel>

                  <Controller
                    control={control}
                    name="telephone"
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        className="phone-input-container"
                        placeholder="Enter phone number"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="NG"
                        // defaultCountry={countryCode.replace('+', '')}
                        // value={phoneNumber}
                        // countries={['US', 'GB', 'IN','NG']}
                        value={value}
                        onChange={onChange}
                        setValueAs={(value: string) =>
                          value ? parsePhoneNumberFromString(value)?.number : ""
                        }
                        // countries={countries}
                        countries={countries.map(
                          (country: any) => country.code
                        )}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        disableDropdown
                      />
                    )}
                  />
                </IonItem>
                {isInputActive && (
                  <div>
                    <IonIcon
                      style={{ fontSize: "14px", color: "#ffa909" }}
                      icon={alertCircleOutline}
                    />
                    &nbsp;
                    <span
                      style={{ fontFamily: "Gilroy-regular", color: "#ffa909" }}
                    >
                      Please ensure you register with your whatsapp number
                    </span>
                  </div>
                )}
              </>
              <>
                <IonItem lines="none">
                  {/* <IonLabel  style={{ padding: "0px 20px ", marginTop: "-20px" }} position="stacked">{t("signuppassword")}</IonLabel> */}
                  <Controller
                    control={control}
                    name="password"
                    render={({ field: { onChange } }) => (
                      <IonInput
                        style={{
                          border: "2px solid #E6E6E6",
                          borderRadius: "99px",
                          height: "46px",
                          backgroundImage:
                            "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
                          textIndent: "20px",
                        }}
                        placeholder="password"
                        type="password"
                        onIonChange={onChange}
                      ></IonInput>
                    )}
                  />
                </IonItem>

                {errors.password && (
                  <IonText style={{fontSize:"14px"}}  color="danger">
                    <span>{t("signuppassworderror")}</span>
                  </IonText>
                )}
              </>
              <IonItem lines="none">
       <Controller
        control={control}
        name="confirmPassword"
        render={({ field: { onChange } }) => (
          <IonInput
            style={{
              border: "2px solid #E6E6E6",
              borderRadius: "99px",
              height: "46px",
              backgroundImage:
                "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
              textIndent: "20px",
            }}
            placeholder="confirm password"
            type="password"
            onIonChange={onChange}
          
          ></IonInput>
        )}
      />





</IonItem>
{errors.confirmPassword && (
  <IonText style={{fontSize:"14px"}} color="danger">
    <span>{errors.confirmPassword.message?.toLowerCase()}</span>
  </IonText>
)}

              <>
                <IonItem lines="none">
                  {/* <IonLabel style={{padding: "0px 10px 20px  ",  }} position="stacked">
                    <IonText>{t("signupreferral")}</IonText> &nbsp;
                    <span>
                      <small>{t("signupreferraloptional")}</small>
                    </span>
                  </IonLabel> */}
                  <Controller
                    control={control}
                    name="referral"
                    render={({ field: { onChange } }) => (
                      <IonInput  style={{
                        border: "2px solid #E6E6E6",
                        borderRadius: "99px",
                        height: "46px",
                        backgroundImage:
                          "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
                        textIndent: "20px",
                      }} placeholder="Referral Code (Optional)" type="text" onIonChange={onChange}></IonInput>
                    )}
                  />
                </IonItem>
              </>
           
              {/* <IonButton
                mode="ios"
                color="primary"
                expand="full"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? t("signupcreate") : t("signupregister")}
              </IonButton> */}
              <IonText color="medium" class="auth__sub-title">
                
                <div>
                        <IonIcon
                      style={{ fontSize: "18px", color: "#005E25" }}
                      icon={alertCircleOutline}
                    />
                    &nbsp;
                    <span
                      style={{ fontFamily: "Gilroy-medium", color:"black" }}
                    >
                    {t("signupcreateotp")} 
                    </span>
                    </div>
                {/* <p>
                  {t("signupcreateotp")}{" "}
                  <a href="https://parkwell.ng/privacy-policy">
                    {t("signupcreatepolicy")}
                  </a>
                  ,{" "}
                  <a href="https://parkwell.ng/terms-of-service">
                    {" "}
                    {t("signupcreateservice")}{" "}
                  </a>{" "}
                  {t("signupcreateand")}{" "}
                  <a href="https://parkwell.ng/payment-terms">
                    {t("signupcreatepayterm")}
                  </a>
                </p> */}
              </IonText>
              <IonText style={{fontSize:"12px"}} color="danger">{errorMessage}</IonText>
            </>
          )}

          {/* 
          <>
            <IonItem>
              <IonLabel position="floating">Phone Number</IonLabel>
              <Controller
                control={control}
                name="telephone"
                render={({ field: { onChange } }) => <IonInput type="tel" onIonChange={onChange}></IonInput>}
              />
            </IonItem>

            {formState.errors.telephone && (
              <IonText color="danger">
                <span>Please enter your Phone Number</span>
              </IonText>
            )}
          </> */}
            {
          steps < 1 ? (
            <div style={{ display: "flex" }} className="next-button-container">
            <IonButton
              onClick={() => setSteps(steps + 1)}
              className="n-button"
              shape="round"
              mode="ios"
              expand="full"
             
              disabled={isSubmitting}
            >
              Next
            </IonButton>
          </div>
          ): (
            <div style={{ display: "flex" }} className="next-button-container">
            <IonButton
              onClick={() => setSteps(steps + 1)}
              className="n-button"
              shape="round"
              mode="ios"
              expand="full"
              type="submit"
              disabled={isSubmitting}
            >
                {isSubmitting ? t("signupcreate") : t("signupregister")}
            </IonButton>
          </div>
          )
        }
        </form>
      
    
        <br />
        <div style={{ display: "flex" }} className="next-button-container">
          <IonText
            style={{ marginLeft: "-40px" }}
            class="auth__form__link"
            color="success"
          >
            <Link to="/auth/login">{t("signuptologin")}</Link>
          </IonText>
        </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Register;
