import "../styles/auth.scss";
import "../../../assets/fonts/fonts.scss";
import * as yup from "yup";
import "@codetrix-studio/capacitor-google-auth";
import { Plugins } from '@capacitor/core';

import { Controller, useForm } from "react-hook-form";
import {
  IonButton,
  IonCard,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { LoginFormData, useAuth } from "../../../services/auth/useAuth";
import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { Preferences } from "@capacitor/preferences";
import { changeLanguage } from "./../../../../node_modules/i18next/index.d";
import i18n from "../../../i18n";
// import PhoneNumber from "libphonenumber-js";
import { isValidPhoneNumber } from "react-phone-number-input";
import parsePhoneNumberFromString from "libphonenumber-js/min";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import parkwellLogo from "../../../assets/img/svglogo.svg";
import googleLogo from "../../../assets/img/g-svg.svg";
import { eyeOffOutline, eyeOutline, logoApple, logoGoogle } from "ionicons/icons";
import { environment } from "../../../services/environment";
import { AxiosContext } from "../../../services/axios";
// import { GoogleLogin } from 'react-google-login';
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "fr",
    name: "Français",
    country_code: "sn",
  },
];

const LANGUAGES = ["English", "French"];
const TEXTS = ["Change Language", "Changer la langue"];

const Login = () => {
  const [languageIndex, setLanguageIndex] = useState(0);
  const { t }:any = useTranslation();
  const [inputActive, setInputActive] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const axios = useContext(AxiosContext);
  const handleInputFocus = () => {
    setInputActive(true);
  };

  const handleInputBlur = () => {
    setInputActive(false);
  };
  const validationSchema = yup.object().shape({
    telephone: yup.number().required("Please enter a phone number"),
    password: yup.string().required(),
  });

  const { control, handleSubmit, formState } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });

  const history = useHistory();
  const { login, getAuthToken, storeToken, storeUserDetails } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const countries: any = [
    { label: "United States", value: "+1", code: "US" },
    { label: "Nigeria", value: "+234", code: "NG" },
    { label: "United Kingdom", value: "+44", code: "GB" },
    { label: "India", value: "+91", code: "IN" },
    { label: "Senegal", value: "+221", code: "SN" },
  ];

  const loginUser = (data: LoginFormData) => {
    setIsSubmitting(true);

    login(data)
      .then(async (response) => {
      
        const { isParkOwner } = response.data.data;
        if (isParkOwner) {
          setErrorMessage(
            "A Partner account exists with this phone number. To continue here, please register with a different phone number"
          );
          setIsSubmitting(false);
        } else {
          await storeToken(response.data.access_token);
          await storeUserDetails(response.data.data);
          await Preferences.set({ key: "secret", value: response.data.chatData.secret });
          console.log("response", response.data);
          setIsSubmitting(false);

          history.push("/home");
        }
      })
      .catch(() => {
        setErrorMessage("Wrong username or password!"); //TO-DO => Update to use toast...
        setIsSubmitting(false);
      });
  };


  GoogleAuth.initialize({
    clientId: '959566598433-8502bcsjbtjqkvt2oo64r4v2jn6027nt.apps.googleusercontent.com',
    scopes: ['profile', 'email'],
    grantOfflineAccess: true,
  });


  const signIn = async () => {

    const result = await GoogleAuth.signIn();
    console.info('result1', result);
    console.info('result', result.authentication.accessToken);
    if (result) {
      try {
        const response = await axios.post(`${environment.backendURL}/auth/google/login`, {
          token: result.authentication.accessToken,
        });
console.log(response);
        const { isParkOwner } = response.data.data;
        if (isParkOwner) {
          setErrorMessage(
            "A Partner account exists with this phone number. To continue here, please register with a different phone number"
          );
          setIsSubmitting(false);
        } else {
          await storeToken(response.data.access_token);
          await storeUserDetails(response.data.data);
          setIsSubmitting(false);
  
          history.push("/home");
        }
      } catch (error) {
        setErrorMessage("Wrong username or password!");
        setIsSubmitting(false);
        console.log(error);
      }
    }

  }

//   const loginwg = useGoogleLogin({
//     onSuccess: async (codeResponse) => {
//    console.log(codeResponse);
//       try {
//         const response = await axios.post(`${environment.backendURL}/auth/google/login`, {
//           token: codeResponse?.access_token,
//         });
// console.log(response);
//         const { isParkOwner } = response.data.data;
//         if (isParkOwner) {
//           setErrorMessage(
//             "A Partner account exists with this phone number. To continue here, please register with a different phone number"
//           );
//           setIsSubmitting(false);
//         } else {
//           await storeToken(response.data.access_token);
//           await storeUserDetails(response.data.data);
//           setIsSubmitting(false);
  
//           // history.push("/home");
//         }
//       } catch (error) {
//         setErrorMessage("Wrong username or password!");
//         setIsSubmitting(false);
//         console.log(error);
//       }
//     },
//   });
  

  /**
   * When Screen Loads, and user is authed, navigate to the home
   */
  useEffect(() => {
    const loadToken = () => {
      getAuthToken().then((token) => {
        console.log(token);
        if (token.value) {
          history.push("/home");
        }
      });
    };

    loadToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLang = (l: any) => {
    return async () => {
      i18n.changeLanguage(l);

      await Preferences.set({
        key: "lang",
        value: l,
      });
    };
  };

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedlang, setSelectedLang] = useState<any>("");
  const handleSelection = async (e: any) => {
    setSelectedLang(e.detail.value);
    i18n.changeLanguage(e.detail.value);
    console.log("e is here", e);
    await Preferences.set({
      key: "lang",
      value: e.detail.value,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLanguageIndex((prevIndex) => (prevIndex + 1) % LANGUAGES.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);


 

  // const loginwg = useGoogleLogin({
  //   onSuccess: async codeResponse => {
  //  await axios
  //   .post(`${environment.backendURL}/auth/google/login`, {
  //     token: codeResponse?.access_token,
  //   })
  //   .then((response:any) => {
  //     // setPost(response.data);
  //     let data = response;
 
  //     console.log("hi",data);
  //     console.log("hey",data.data);
 
 
  //   });

  //     console.log("coderesponse",codeResponse)},
    
  // });

  return (
    <IonContent
      class="auth"
      style={{
        "--padding-top": "120px",
        "--padding-start": "16px",
        "--padding-end": "16px",
        "--padding-bottom": "30px",
      }}

    >
           <div className="logo-container">
        <img
          src={parkwellLogo} // Use the imported image asset here
          alt="Parkwell Logo"
          className="logo-image"
        />
      </div>
      <div className="login-card">
      <IonText class="auth__title">
        <h3>{t("welcome-back")}</h3>
      </IonText>

      <IonText>
        <p
          style={{
            color: "#808080",
            fontSize: "1rem",
            fontWeight: " 500",
            lineHeight: "1.25rem",
            letterSpacing: "-0.02em",
            textAlign: "left",
          }}
        >
          Log in with your phone number or email address
        </p>
      </IonText>

 

      <form className="auth__form" onSubmit={handleSubmit(loginUser)}>
        <>
          <IonItem lines="none">
            <IonLabel>{t("loginphonenum")}</IonLabel>

            <Controller
              control={control}
              name="telephone"
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  className="phone-input-container"
                  placeholder="Phone Number"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="NG"
                  // defaultCountry={countryCode.replace('+', '')}
                  // value={phoneNumber}
                  // countries={['US', 'GB', 'IN','NG']}
                  value={value}
                
                  onChange={onChange}
                  setValueAs={(value: string) =>
                    value ? parsePhoneNumberFromString(value)?.number : ""
                  }
                  // countries={countries}
                  countries={countries.map((country: any) => country.code)}
                  disableDropdown
                  // onChange={handlePhoneNumberChange}
                />
              )}
            />
          </IonItem>
        </>
        {/* <IonItem>
          <IonLabel position="floating">Phone Number</IonLabel>

          <Controller
            control={control}
            name="telephone"
            render={({ field: { onChange } }) => <IonInput autocomplete="tel" type="tel" onIonChange={onChange}></IonInput>}
          />
        </IonItem> */}

        {/* {formState.errors.telephone && (
          <IonText color="danger">
            <span>Please enter the Phone Number</span>
          </IonText>
        )} */}

        <IonItem lines="none">
          {/* <IonLabel
          style={{ padding: "0px 20px ", marginTop: "-20px" }} position="stacked"
          >
            {t("login-password")}
          </IonLabel> */}
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange } }) => (
              <><IonInput
                style={{
                  border: "2px solid #E6E6E6",
                  borderRadius: "99px",
                  height: "46px",
                  backgroundImage: "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
                  textIndent: "20px",
                  fontFamily: "gilroy-mdeium"
                }}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder={t("login-password")}
                autocomplete="current-password"
                type={passwordVisible ? "text" : "password"}
                onIonChange={onChange}
              ></IonInput></>
            )}
          />
         {
          inputActive ?   <IonIcon  onClick={() => setPasswordVisible(!passwordVisible)}  slot="end" icon={passwordVisible ? eyeOffOutline : eyeOutline} /> : ""
         }
                
              
        </IonItem>
        {formState.errors.password && (
          <IonText color="danger">
            <span>{t("login-password-error")}</span>
          </IonText>
        )}
        <br />
     
        <IonItem
          style={{
            border: "2px solid #E6E6E6",
            borderRadius: "99px",
            height: "46px",
            backgroundImage:
              "linear-gradient(0deg, #e6e6e6, #e6e6e6), linear-gradient(0deg, #f2f2f2, #f2f2f2)",
          }}
        >
          <IonLabel style={{ padding: "0px 20px" }}>
            {TEXTS[languageIndex]}
          </IonLabel>
          <IonSelect
            value={selectedlang}
            mode="ios"
            okText="Okay"
            onIonChange={(e: any) => handleSelection(e)}
          >
            {languages.map(({ code, name, country_code }) => (
              <IonSelectOption key={country_code} value={code}>
                <button key={country_code} value={code}>
                  {name}
                </button>
                {/* {`${country.name} (${country.code})`}  */}
                {/* <div className={`flag-icon flag-icon-${country_code}`}></div> 
              {name} */}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonText class="action-container" color="success">
          <Link className="reg" to="/auth/reset">
            {t("loginforgotpassword")}
          </Link>
        </IonText>
        {/* <p></p> */}
        <IonButton
          className={inputActive ? "la-button" : "l-button"}
          shape="round"
          mode="ios"
          expand="full"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? t("loginsignin-in") : t("Continue")}
        </IonButton>

        <IonText
          style={{
            color: "#808080",
            textAlign: "center",
            fontFamily: "gilroy-medium",
            margin: "20px 0px",
            fontSize: "14px",
          }}
        >
          <span>Or login with</span>
        </IonText>
       
        <div>
          <IonButton  className="g-button" shape="round" expand="full" onClick={() => signIn()}>
            <IonIcon slot="start" icon={googleLogo} />
            Sign in with Google
          </IonButton>
          {/* <IonButton  className="g-button" shape="round" expand="full" onClick={() => loginwg()}>
            <IonIcon slot="start" icon={googleLogo} />
            Sign in with Google
          </IonButton> */}
{/* 
          <IonButton className="i-button" shape="round" expand="full">
            <IonIcon slot="start" icon={logoApple} />
            Sign up with your Apple ID
          </IonButton> */}
        </div>
        <IonText color="danger">{errorMessage}</IonText>
        <IonText class="action-container" color="success">
          <Link className="reg-f" to="/auth/register">
            {t("login-sign-up-now")}
          </Link>
        </IonText>

        {/* <IonText class="action-container" color="success">
          <span></span>
          <Link className="reset" to="/auth/reset">
            {t("loginforgotpassword")}
          </Link>
        </IonText> */}
      </form>
      </div>
    </IonContent>
  );
};

export default Login;
