import { useLayoutEffect, useState } from "react";

import { Preferences } from '@capacitor/preferences';
import { Storage } from "@capacitor/storage";
import axios from "axios";
import { environment } from "../environment";

export type LoginFormData = {
  telephone: string;
  password: string;
};

export type RegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  password: string;
  country: string;
  referral: string;
  confirmPassword: string;
};

export type VerifyOTPFormData = {
  otp: number;
};

export const useAuth = () => {
  const register = (userData: RegisterFormData) => {
    const data = { ...userData, isParkOwner: false };
    //data.telephone = `+234${data.telephone.toString().substring(1)}`;
    data.telephone = `+${data.telephone.toString()}`;
    return axios.post(`${environment.backendURL}/auth/register`, data);
  };

  const login = (userData: LoginFormData) => {
    const data = { ...userData };
    data.telephone = `+${data.telephone.toString()}`;
    console.log("telephone", data.telephone);
    return axios.post(`${environment.backendURL}/auth/login`, data);
  };

  const resetPwd = (userData: LoginFormData) => {
    const data = { ...userData };
    data.telephone = `+${data.telephone.toString()}`;
    return axios.post(`${environment.backendURL}/auth/reset`, data);
  };

  const verifyResetPassword = (pin: string, userId: string, password: string) => {
    const data = {
      pin,
      userId,
      password,
    };
    return axios.post(`${environment.backendURL}/auth/verifyReset`, data);
  };

 
  const verifyOTP = (pin: string, pinId: string, userId: string) => {
    const data = {
      pinId,
      pin,
      userId    
    };
   return axios.post(`${environment.backendURL}/auth/verifyOTP`, data);
 };


  const storeToken = (token: string) => {
    Preferences.set({ key: "token", value: token });
  };

  const logOut = async () => {
    await Preferences.remove({ key: "token" });
  };

  

  const storeUserDetails = (userData: any) => {
    Preferences.set({ key: "user", value: JSON.stringify(userData) });
  };

  const getUserDetails = async (temp?: string) => {
    if (!temp) {
      return await Preferences.get({ key: "user" });
    } else {
      return await Preferences.get({ key: temp });
    }
  };

  const getUserId = async () => {
    const user = await getUserDetails();
    if (user.value) {
      const detail = JSON.parse(user.value);
      return detail.id;
    }
    return null;
  };

  const getAuthToken = async () => {
    return Preferences.get({ key: "token" });
  };
  const getChatSecret = async () => {
    return Preferences.get({ key: "secret" });
  };

  const isLoggedIn = async (): Promise<boolean> => {
    const token = await Preferences.get({ key: "token" });
   
    return token.value != null;
  };

  return {
    login,
    resetPwd,
    verifyResetPassword,
    register,
    verifyOTP,
    storeToken,
    storeUserDetails,
    getUserDetails,
    getAuthToken,
    getChatSecret,
    isLoggedIn,
    getUserId,
    logOut,
    
  };
};

export const useUserId = () => {
  const [userId, setUserId] = useState();
  const { getUserId } = useAuth();

  useLayoutEffect(() => {
    getUserId().then((id) => {
      setUserId(id);
    });
  });

  return userId;
};
