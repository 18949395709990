import gql from "graphql-tag";

export const getTransactionsSub = gql`
subscription TransactionsSub {
  wallet_transaction (order_by: {createdAt: desc}) {
    amount
    direction
    reference
    type
    createdAt
    id
    updatedAt
  }
}
`;

export  const WalletTransactions = gql`
query WalletTransactions($id: Int) {
  wallet_transaction(where: {wallet: {users: {id: {_eq: $id}}}}, order_by: { createdAt: desc }) {
    amount
    id
    type
    direction
    updatedAt
    createdAt
    wallet {
      balance
      currency
      id
      updatedAt
      createdAt
    }
    walletId
  }
}
`
export const GetUserBanks = gql`
query GetUserBanks($id: Int) {
  banks(where: {park: {user: {id: {_eq: $id}}}}) {
    accountName
    accountNumber
    bankCode
    bankName
    id
  }
}
`

export const GetUserWalletInfo = gql`
query GetUserWalletInfo($id: Int) {
  users(where: {id: {_eq: $id}}) {
    firstName
    id
    lastName
    wallet {
      balance
      id
      currency
    }
  }
}
`;


export const fundWallet = gql`
  mutation fundWallet($id: Int, $objects: wallets_set_input) {
    update_wallets (
    where: {id: {_eq: $id}},
      _set: $objects
    ) {
      affected_rows
      returning {
        id,
        balance,
        type
      }
    }
  }
`




export const AddWalletTransaction = gql`
  mutation AddWalletTransaction($objects: [wallet_transaction_insert_input!]!) {
    insert_wallet_transaction (objects: $objects) {
      affected_rows
      returning {
        id
        walletId
        amount
        reference
        direction
        type
      }
    }
  }
`