 export const environment = {
 production: true,
  graphURI: "https://parkwell-new-hasura.herokuapp.com/v1/graphql",
  graphWSURI: "wss://parkwell-new-hasura.herokuapp.com/v1/graphql",
  backendURL: "https://parkwell-web-service.herokuapp.com",
  backendURL_two: "https://parkwellbackend2.vercel.app",
  googleApiKey: "AIzaSyClI93us6CcSRQj2xf5bRl5ommZETwe0v8",
  googleClientId: "959566598433-8502bcsjbtjqkvt2oo64r4v2jn6027nt.apps.googleusercontent.com",
  HasuraSecret: "9A1X2doJanowY1o5281Uy5MURpTJDFqU1Uq0aoEwUXI29Alwbrue1P70yHatHDJH",
  cloud_name: "parkwell",
  cloud_preset: "unsigned_parks_default",
  paystack_key: "pk_live_fcbf3209c620ac68a2efbf06a32f9c713a10ae95",
};
//  export const environment = {
//  production: false,
//   graphURI: "https://backend-dev-parkwell.hasura.app/v1/graphql",
//   graphWSURI: "wss://backend-dev-parkwell.hasura.app/v1/graphql",
//   backendURL: "https://parkwell-web-service-dev.herokuapp.com",
//   googleApiKey: "AIzaSyClI93us6CcSRQj2xf5bRl5ommZETwe0v8",
//   HasuraSecret: "O18h6noXLLQw8pcXkpShrp7hSbTBl36ekaBMoedcH11lwXIxREbujusXfQpZdDMZ",
//   cloud_name: "parkwell",
//   cloud_preset: "unsigned_parks_default",
// };


//  export const environment = {
//    production: true,
//    graphURI: "https://backend-parkwell-graph.herokuapp.com/v1/graphql",
//    graphWSURI: "wss://backend-parkwell-graph.herokuapp.com/v1/graphql",
//    backendURL: "https://parkwell-web-service.herokuapp.com",
//    googleApiKey: "AIzaSyClI93us6CcSRQj2xf5bRl5ommZETwe0v8",
//    cloud_name: "parkwell",
//    cloud_preset: "unsigned_parks_default",
//  };                                                           