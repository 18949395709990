import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tEn from './locales/en/translation.json';
import tFr from './locales/fr/translation.json';

const resources = {
  en: {
    translation: tEn
  },
  fr: {
    translation: tFr
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'fr'
  });

export default i18n;