/* Theme variables */
import "./theme/variables.scss";

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import {
  IonApp,
  IonBadge,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
 
} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import { AxiosContext } from "./services/axios/";
import Login from "./pages/auth/login/";
import MapRoute from "./components/MapRoute";
import Onboard from "./pages/onboarding/Onboard";
import PrivateRoute from "./components/Routes/PrivateRoute";
import Register from "./pages/auth/register/";
import Scanner from "./pages/Scanner";
import Search from "./pages/Search";
import Success from "./components/Success";
import axios from "axios";
import { environment } from "./services/environment";
import i18n from "./i18n";
import i18next from "i18next";
import { setContext } from "@apollo/client/link/context";
import { useAuth } from "./services/auth/useAuth";
import { useHistory } from "react-router-dom";
import Home from "./pages/Home/Home";
import homeline from "./assets/img/homeline.svg";
import fileres from "./assets/img/fileres.svg";
import fabicon from "./assets/img/fabIcon.svg";
import creditcard from "./assets/img/creditcard.svg";
import personicon from "./assets/img/personicon.svg";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppUrlListener from "./components/urllistener/AppUrlListener";
import Spinner from "./components/Spinner";
import { setupIonicReact } from '@ionic/react';
import Chatpage from "./pages/chat/chat";
import { chatbubbleOutline } from "ionicons/icons";
import { Preferences } from "@capacitor/preferences";
import ResetOtp from "./pages/auth/reset-otp";
import ResetPassword from "./pages/auth/reset";

const App: React.FC = () => {

 



setupIonicReact({
  mode: 'md'
});
  const Wallet = lazy(() => import("./pages/Wallet"));
  const Utilities = lazy(() => import("./pages/utilities/airtime'n'data"));
  // const Wallet = lazy(() => wait(3000).then(() => import("./pages/Wallet")));
  const Faqs = lazy(() => import("./pages/faqs"));
  // const Chatpage = lazy(() => import("./pages/chat/chat"));
  const Profile = lazy(() => import("./pages/Profile"));
  const Reserved = lazy(() => import("./pages/Reserved"));
  const ParkSelection = lazy(() => import("./components/subscriptions/ParkSelection"));
  const ReservationDetails = lazy(() => import("./pages/ReservationDetails"));
  const ParkDetails = lazy(() => import("./components/ParkDetails"));
  const WalletSummary = lazy(() => import("./components/WalletSummary"));
  const Vouchers = lazy(() => import("./pages/Vouchers"));
  const VerifyOTP = lazy(() => import("./pages/auth/verify-otp/"));
  const Verify = lazy(
    () => import("./components/FundWalletWithPaystack/verify")
  );
  const VehicleList = lazy(() => import("./pages/VehicleList"));
  const Tickets = lazy(() => import("./pages/Tickets"));
  const Support = lazy(() => import("./pages/support"));
  const Summary = lazy(() => import("./pages/Summary"));
  const Settings = lazy(() => import("./pages/Settings/Settings"));
  const Scanner = lazy(() => import("./pages/Scanner"));
  const ReferralPage = lazy(() => import("./pages/Referral/referral"));
  const Recommendations = lazy(() => import("./pages/Recommendations"));
  // const ResetPassword = lazy(() => import("./pages/auth/reset"));
  // const ResetOtp = lazy(() => import("./pages/auth/reset-otp"));
  const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
  const PayuInsurance = lazy(() => import("./pages/Insurance/Payu/payupage"));
  const PayWithSavedCard = lazy(
    () => import("./components/FundWalletWithPaystack/paywithsavedcard")
  );
  const Pay = lazy(() => import("./components/FundWalletWithPaystack/pay"));
  const Offices = lazy(() => import("./pages/Offices"));
  const OfficeDetails = lazy(() => import("./components/OfficeDetails"));
  const OfficeBookings = lazy(() => import("./pages/Offices/pages/bookings"));
  const Notifications = lazy(() => import("./pages/Notifications"));
  const MyCards = lazy(
    () => import("./components/FundWalletWithPaystack/AllCards")
  );
  const MyLogin = lazy(() => import("./pages/auth/login/mylogin"));
  const Logistics = lazy(() => import("./pages/Logistics"));
  const Insurancepage = lazy(() => import("./pages/Insurance/insurance"));
  const InsuranceOrders = lazy(
    () => import("./pages/Insurance/insuranceorders")
  );
  const InsuranceOrderDetails = lazy(
    () => import("./pages/Insurance/insuranceorderdetails")
  );
  const InsuranceFrontPage = lazy(() => import("./pages/Insurance/frontpage"));
  const InsuranceDetails = lazy(
    () => import("./pages/Insurance/insurancedetails")
  );
  const InsuranceClaimsListing = lazy(
    () => import("./pages/Insurance/insuranceClaimsListing")
  );
  const InsuranceClaimsDetails = lazy(
    () => import("./pages/Insurance/claimslistdetails")
  );
  const InsuranceClaims = lazy(
    () => import("./pages/Insurance/insuranceclaims")
  );
  const FundWalletwithPaystack = lazy(
    () => import("./components/FundWalletWithPaystack")
  );
  const FundWallet = lazy(() => import("./components/FundWallet"));
  const Favorites = lazy(() => import("./pages/Favorites"));
  const Events = lazy(() => import("./pages/Events/Event"));
  const EventPackList = lazy(() => import("./components/EventParkList"));
  const Editprofile = lazy(() => import("./pages/Editprofile/EditProfile"));
  const DeliveryPage = lazy(() => import("./pages/delivery/delivery"));
  const CompleteReservation = lazy(
    () => import("./components/CompleteReservation")
  );
  const CarbonSidePage = lazy(() => import("./pages/Carbonside/Carbonside"));
  const CarHirePage = lazy(() => import("./pages/CarHire/carhiring"));
  const Bookmark = lazy(() => import("./pages/Bookmark"));
  const BankList = lazy(() => import("./components/BankList"));
  const AddVehicleDetail = lazy(
    () => import("./pages/Insurance/addvehicledetail")
  );
  const AddCarNumber = lazy(() => import("./components/AddCarNumber"));
  const AddBank = lazy(() => import("./pages/Banks/AddBank"));
  const SubscribedParks = lazy(() => import("./components/subscriptions/parks"));
  // const Profile = lazy(() => import("./pages/Profile"));
  const { isLoggedIn } = useAuth();

  const [currentUser, setCurrentUser] = useState<any>("");

  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    const checkLogin = async () => {
      const check = await isLoggedIn();
      setLoggedIn(check);
    };
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  let homer = <img src="./assets/svg/home.svg" alt="" />;

  const history = useHistory();
  const { getUserDetails } = useAuth();

  const { getAuthToken } = useAuth();

  const [authToken, setAuthToken] = useState<string>();
  const { getChatSecret } = useAuth();

  const [chatToken, setChatToken] = useState<any>("");

  useEffect(() => {
    if (chatToken == null || chatToken === "undefined" || chatToken === "") {
      getChatSecret().then((secret:any) => {
        if (secret.value != null) {
          setChatToken(secret.value);
        }
      });
    }
  });
  useEffect(() => {
    if (authToken == null || authToken === "undefined") {
      getAuthToken().then((token) => {
        if (token.value != null) {
          setAuthToken(token.value);
        }
      });
    }
  });

  const link = new HttpLink({ uri: environment.graphURI });
  const headerLink = setContext((request, previousContext) => ({
    headers: {
      // Make sure you include any existing headers!
      ...previousContext.headers,
      Authorization: `Bearer ${authToken}`,
      "x-hasura-admin-secret": environment.HasuraSecret,
    },
  }));

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: headerLink.concat(link),
  });

  axios.defaults.baseURL = "http://localhost:1010/";
  axios.defaults.headers.common = { Authorization: `bearer ${authToken}` };
  const [userProfile, setUserProfile] = useState<any>("");
 

  useEffect(() => {
    const getUser = () => {
      getUserDetails().then((detail:any) => {
        if (detail.value) {
          const user = JSON.parse(detail.value);
          setCurrentUser(user);
        }
      });
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  console.log("prof from country", userProfile.language);
  useEffect(() => {
    if (currentUser?.country === "Senegal") {
      i18n.changeLanguage("fr");
    } else {
      console.log("user is not in Senegal");
      i18n.changeLanguage(currentUser?.language);
    }
  }, [currentUser]);

  const queryClient = new QueryClient();

  return (
    <IonApp>
      <ApolloProvider client={client}>
        <AxiosContext.Provider value={axios}>
        <GoogleOAuthProvider clientId={environment.googleClientId}>
          <IonReactRouter >
      
            <Switch>
            {/* <AppUrlListener></AppUrlListener> */}
            <Route
  exact
  path="/"
  render={(props) => {
    return loggedIn ? <Login/> : <Onboard/> ;
  }}
/>

<Route
  exact
  path="/chat"
  render={() => {
    if (!currentUser?.id || currentUser?.email === "") {
      return <Spinner />;
    } else {
      return <Chatpage username={currentUser?.email} token={currentUser?.id} />;
    }
  }}
/>


              {/* <Route exact path="/" component={Onboard} /> */}
              <Route exact path="/auth/login" component={Login} />
              <Route exact path="/auth/register" component={Register} />
            
              <PrivateRoute exact path="/auth/verifyOtp" component={VerifyOTP} />
              <Route exact path="/auth/register/onboard" component={Onboard} />
          
              <Route exact path="/auth/resetOtp" component={ResetOtp} />
        
           
              <Route exact path="/auth/reset" component={ResetPassword} />
        
              <PrivateRoute path="/addBank" component={AddBank} exact />
              <PrivateRoute path="/mylogin" component={MyLogin} exact />
              <PrivateRoute
                path="/carbonside"
                component={CarbonSidePage}
                exact
              />
            <PrivateRoute path="/parkselection" component={ParkSelection} exact />

            <PrivateRoute path="/utilities" component={Utilities} exact />
              <PrivateRoute path="/office" component={Offices} exact />
              <PrivateRoute
                path="/officebookings"
                component={OfficeBookings}
                exact
              />

              {/* <PrivateRoute
path="/officebookings"
                        component={OfficeBookings}
                        exact
                      /> */}

              <Route
                path="/"
                render={() => (
                  <IonContent >
                    {/* <IonFab vertical="bottom" horizontal="center">
                      <IonFabButton href="/search">
                        <IonIcon icon={add} />
                      </IonFabButton>
                    </IonFab> */}
                    <IonTabs >
                      <IonRouterOutlet>
                        {/* <PrivateRoute path="/" component={Onboard} exact /> */}
                        <PrivateRoute path="/home" component={Home} exact />
                        {/* <PrivateRoute path="/homepage" component={Page} exact /> */}
                        <PrivateRoute
                          path="/profile"
                          component={Profile}
                          exact
                        />
                        <PrivateRoute path="/events" component={Events} exact />
                        <PrivateRoute path="/faqs" component={Faqs} exact />

                        <PrivateRoute
                          path="/carhire"
                          component={CarHirePage}
                          exact
                        />
                        <PrivateRoute
                          path="/logistics"
                          component={Logistics}
                          exact
                        />
                        <PrivateRoute
                          path="/referral"
                          component={ReferralPage}
                          exact
                        />
                        <PrivateRoute path="/verify" element={<Verify />} />
                        <PrivateRoute path="/pay" element={<Pay />} />
                        <PrivateRoute path=":reference" element={<Verify />} />
                        <PrivateRoute
                          path="/events/:address"
                          component={EventPackList}
                          exact
                        />
                        <PrivateRoute
                          path="/reserved"
                          component={Reserved}
                          exact
                        />
                        <PrivateRoute
                          path="/delivery"
                          component={DeliveryPage}
                          exact
                        />
                        <PrivateRoute
                          path="/insurance"
                          component={Insurancepage}
                          exact
                        />
                        <PrivateRoute
                          path="/insurancefront"
                          component={InsuranceFrontPage}
                          exact
                        />

                        <PrivateRoute path="/wallet" component={Wallet} exact />

                        <PrivateRoute
                          path="/map-route/:id"
                          component={MapRoute}
                          exact
                        />
                        <PrivateRoute
                          path="/summary"
                          component={Summary}
                          exact
                        />
                        <PrivateRoute
                          path="/complete-reservation"
                          component={CompleteReservation}
                          exact
                        />
                        <PrivateRoute
                          path="/fund-wallet/monnify"
                          component={FundWallet}
                          exact
                        />
                        <PrivateRoute
                          path="/walletfund/paystack"
                          component={FundWalletwithPaystack}
                          exact
                        />

                          <PrivateRoute
                            path="/park/:id"
                            component={ParkDetails}
                            exact
                          />
                     
                        <PrivateRoute
                          path="/office/:id"
                          component={OfficeDetails}
                          exact
                        />
                        <PrivateRoute
                          path="/subscribedparks"
                          component={SubscribedParks}
                          exact
                        />
                        <PrivateRoute
                          path="/orders/:id"
                          component={InsuranceOrderDetails}
                          exact
                        />
                        <PrivateRoute
                          path="/claims/:id"
                          component={InsuranceClaimsDetails}
                          exact
                        />
                        <PrivateRoute
                          path="/createclaim"
                          component={InsuranceClaims}
                          exact
                        />
                        <PrivateRoute
                          path="/listclaim"
                          component={InsuranceClaimsListing}
                          exact
                        />
                        <PrivateRoute
                          path="/insurance/:id"
                          component={InsuranceDetails}
                          exact
                        />
                        <PrivateRoute
                          path="/insurance/vehicledetail/:id"
                          component={AddVehicleDetail}
                          exact
                        />
                        <PrivateRoute
                          path="/orders"
                          component={InsuranceOrders}
                          exact
                        />
                        <PrivateRoute
                          path="/payuinsurance"
                          component={PayuInsurance}
                          exact
                        />
                        <PrivateRoute
                          path="/vehicle"
                          component={VehicleList}
                          exact
                        />
                        <PrivateRoute path="/cards" component={MyCards} exact />
                        <PrivateRoute
                          path="/scanner"
                          component={Scanner}
                          exact
                        />
                        <PrivateRoute
                          path="/settings"
                          component={Settings}
                          exact
                        />
                        {/* <PrivateRoute
                        path="/ticketier"
                        component={Ticketier}
                        exact
                      /> */}
                        <PrivateRoute
                          path="/favorites"
                          component={Favorites}
                          exact
                        />

                        <PrivateRoute
                          path="/vouchers"
                          component={Vouchers}
                          exact
                        />

                        <PrivateRoute path="/search" component={Search} exact />
                        <PrivateRoute
                          path="/recommendations"
                          component={Recommendations}
                          exact
                        />

                        <PrivateRoute
                          path="/support"
                          component={Support}
                          exact
                        />

                        <PrivateRoute
                          path="/paywithsavedcard"
                          component={PayWithSavedCard}
                          exact
                        />
                        <PrivateRoute
                          path="/tickets"
                          component={Tickets}
                          exact
                        />
                        <PrivateRoute
                          path="/editprofile"
                          component={Editprofile}
                          exact
                        />
                        <PrivateRoute
                          path="/privacypolicy"
                          component={PrivacyPolicy}
                          exact
                        />
                        <PrivateRoute
                          path="/success/:string"
                          component={Success}
                          exact
                        />
                        <PrivateRoute
                          path="/reserve/:id"
                          component={ReservationDetails}
                          exact
                        />

                        <PrivateRoute
                          path="/add-vehicle-number"
                          component={AddCarNumber}
                          exact
                        />
                        <PrivateRoute
                          path="/notifications"
                          component={Notifications}
                          exact
                        />
                        <PrivateRoute
                          path="/bank-list"
                          component={BankList}
                          exact
                        />

                        <PrivateRoute
                          path="/wallet-summary"
                          component={WalletSummary}
                          exact
                        />
                        <PrivateRoute
                          path="/bookmark"
                          component={Bookmark}
                          exact
                        />
                      </IonRouterOutlet>

                      <IonTabBar  slot="bottom" id="tabs">
                        <IonTabButton tab="home" href="/home">
                          <IonIcon src={homeline} />
                           <IonLabel>Home</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="reserved" href="/reserved">
                          <IonIcon src={fileres} />
                            <IonLabel>Reservation</IonLabel>
                        </IonTabButton>
                        {/* <IonTabButton></IonTabButton> */}
                        <IonTabButton tab="chat" href="/chat">
                          <IonIcon style={{ fontSize: "25px" }} icon={chatbubbleOutline} />
                          <IonLabel>Chat</IonLabel> 
                        </IonTabButton>

                        <IonTabButton tab="wallet" href="/wallet">
                          <IonIcon src={creditcard} />
                            <IonLabel>Park-Wallet</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="profile" href="/profile">
                          <IonIcon src={personicon} />
                            <IonLabel>Profile</IonLabel>
                        </IonTabButton>
                      </IonTabBar>
                    </IonTabs>
                  </IonContent>
                )}
              ></Route>

              {/* <Route
                path="/office"
                render={() => (
                  <IonContent>
                  {/* <IonFab  vertical="bottom" horizontal="center" >
                  <IonFabButton  href="/search">
                    <IonIcon icon={add} />
                  </IonFabButton>
                </IonFab> 
                <IonTabs>
        <IonRouterOutlet>
          <Route  path= "/officebookings" component={OfficeBookings} />
          <PrivateRoute
path="/office"
                        component={Offices}
                        exact
                      />

  
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="home">
            <IonIcon icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>

          <IonTabButton tab="schedule">
            <IonIcon icon={calendar} />
            <IonLabel>Schedule</IonLabel>
            <IonBadge>6</IonBadge>
          </IonTabButton>

          <IonTabButton tab="map">
            <IonIcon icon={map} />
            <IonLabel>Map</IonLabel>
          </IonTabButton>


          <IonTabButton href="/officebookings" tab="about">
            <IonIcon icon={informationCircle} />
            <IonLabel>Bookings</IonLabel>
          </IonTabButton>
       
        </IonTabBar>
      </IonTabs>
                  </IonContent>
                  
                )}

              ></Route> */}
              {/* 
            <Route path="*">
              <Redirect to="/home" />
            </Route> */}
            </Switch>
          </IonReactRouter>
          </GoogleOAuthProvider>
        </AxiosContext.Provider>
      </ApolloProvider>
    </IonApp>
  );
  function wait(time: any) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }
};

export default App;
